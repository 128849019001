@import '/src/styles/constants.module.scss';

.signIn {
  max-width: 400px;
  min-width: 350px;
  max-height: 700px;

  margin: 20px auto;
  padding: 40px 20px 50px 20px;

  box-shadow: 0px 10px 50px 0px #0000000d;
  border-radius: 30px;

  &__heading {
    font: var(--typo-t1);
    text-align: center;

    margin-bottom: 2rem;
    padding: 0 30px;
  }

  &__message {
    opacity: 0.6;

    text-align: center;

    padding: 0 20px;
    margin-bottom: 2rem;
  }

  &__form {
    z-index: 1;

    max-width: 400px;
    min-width: 350px;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    position: relative;
    z-index: 1;
  }
}

.input {
  margin-top: 0.5rem;
}

.login {
  color: #bbbbbb;

  border-bottom: 1px solid #bbbbbb;
  background-color: transparent;
  font: var(--typo-l2);

  width: max-content;
  margin: 0 auto;

  &:hover,
  &:active,
  &:focus {
    color: black;
    background-color: transparent;
    transition: all 0.3s;
    border-bottom: 1px solid black !important;
  }
}
