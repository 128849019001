@import '/src/styles/constants.module.scss';

.signIn {
  max-width: 400px;
  min-width: 350px;
  max-height: 700px;

  padding-bottom: 30px;

  &__form {
    z-index: 1;

    max-width: 400px;
    min-width: 350px;

    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    position: relative;
    z-index: 1;

    padding: 40px 15px 15px;
  }

  &__footer {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: #929292;
    font: var(--typo-l1);
  }
}

.input {
  margin-top: 0.5rem;
}

.button {
  margin-top: calc(3.5rem);
}

.singUp {
  color: var(--dark-gray-1);
  border-bottom: 1px solid var(--dark-gray-1);
}

.forgot,
.singUp {
  background-color: transparent;
  transition: all 0.3s;
  width: max-content;

  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: black;
    background-color: transparent;
    transition: all 0.3s;
    border-bottom: 1px solid black !important;
  }
}

.toc {
  margin-top: 1rem;

  &__link {
    text-decoration: underline;
  }
}
