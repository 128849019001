@import '/src/styles/constants.module.scss';

.recovery {
  max-width: 400px;
  min-width: 350px;
  max-height: 700px;

  margin: 20px auto;
  padding: 40px 20px 40px 20px;

  box-shadow: 0px 10px 50px 0px #0000000d;
  border-radius: 30px;

  &__logo {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 15px;

    a {
      margin: 0;
    }
  }

  &__form {
    z-index: 1;

    max-width: 400px;
    min-width: 350px;

    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    position: relative;
    z-index: 1;
  }
}

.input {
  margin-top: 0.5rem;
}

.button {
  margin-top: 1.5rem;
}
