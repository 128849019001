.container {
  max-width: max-content;

  padding: 20px;
  margin: 20px auto 0 auto;

  box-shadow: 0px 10px 50px 0px #0000000d;
  border-radius: 30px;
}

.tabs {
  max-height: 48px;

  display: flex;
  gap: 5px;

  padding: 5px;
  border-radius: 15px;

  background-color: var(--gray);

  button {
    width: 100%;
    max-width: 100%;

    border-radius: 10px;
    color: var(--black);
  }
}
